import { RelewiseResponsePageTags, RelevantContentItem } from '~/lib/data-contract';
import {
    ContentSearchRequestResponseType,
    RelewiseContentDataTagsTypes,
} from '../../M140ProductsList';
import { RelewiseContentResult } from '../../SearchResults/model';

export const mapRelewiseContentToRelevantContent = (
    page: ContentSearchRequestResponseType
): RelevantContentItem[] => {
    return (page?.results || []).map((item) => {
        return mapRelewiseContentItemToRelevantContent(item);
    });
};

export const mapRelewiseContentItemToRelevantContent = (
    item: RelewiseContentResult
): RelevantContentItem => {
    const title = item.data?.PageReferenceTitle?.value || item.displayName;
    const image = item.data?.PageReferenceCompositionImage?.value?.data;

    const l1Tags = item.data?.[RelewiseContentDataTagsTypes.L1Category]?.value?.$values || [];
    const themeTags = item.data?.[RelewiseContentDataTagsTypes.Theme]?.value?.$values || [];
    const contentTypeTags =
        item.data?.[RelewiseContentDataTagsTypes.ContentType]?.value?.$values || [];

    const displayTags: RelewiseResponsePageTags[] = [
        {
            title: RelewiseContentDataTagsTypes.L1Category,
            tagsList: l1Tags,
        },
        {
            title: RelewiseContentDataTagsTypes.Theme,
            tagsList: themeTags,
        },
        {
            title: RelewiseContentDataTagsTypes.ContentType,
            tagsList: contentTypeTags,
        },
    ];

    const imageObject = image?.Url
        ? {
              src: image?.Url.value,
              height: parseInt(image?.Height?.value || '0'),
              width: parseInt(image?.Width?.value || '0'),
              alt: image?.Alt?.value || '',
              imageFocalPoint: image?.FocalPointX
                  ? {
                        x: parseInt(image?.FocalPointX?.value),
                        y: parseInt(image?.FocalPointY?.value),
                    }
                  : undefined,
              type: 'image',
              id: '',
              name: '',
          }
        : undefined;

    return {
        headline: title,
        callToAction: item.data.Url.value,
        image: imageObject,
        tags: JSON.stringify(displayTags),
    };
};
