import React, { ReactNode, useMemo } from 'react';
import { useFrame } from '~/shared/utils';
import NextLink from 'next/link';
import { Text } from '~/shared/components';
import { StyledTagListLinkAnchor } from './styled';

type TagListLinkProps = {
    children: ReactNode | ReactNode[];
};

export const TagListLink = ({ children }: TagListLinkProps) => {
    const { data } = useFrame();

    const searchPageUrl = useMemo(() => {
        return data?.staticLinks?.searchPage?.url;
    }, [data]);

    if (searchPageUrl != null) {
        return (
            <NextLink
                href={`${searchPageUrl}?search=${children}`}
                passHref
                prefetch={false}
                legacyBehavior
            >
                <StyledTagListLinkAnchor>
                    <Text as="p">{children}</Text>
                </StyledTagListLinkAnchor>
            </NextLink>
        );
    }
    return <Text as="p">{children}</Text>;
};
