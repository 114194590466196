import { useEffect, useState } from 'react';
/**
 * A wrapper to simplify simpler usage of setting state from changing dependencies.
 * @param callback Callback function to set state value
 * @param dependencies list of dependencies to trigger change in state
 * @returns Value supplied by callback function
 */
export const useComputed = <T>(callback: () => T, dependencies: unknown[], defaultValue?: T) => {
    const [state, setState] = useState(defaultValue);

    useEffect(() => {
        const value = callback();
        setState(value);
    }, dependencies);

    return state;
};
