import styled from '@emotion/styled';
import { Link } from '~/shared/components';

export const StyledTagListLinkAnchor = styled(Link)(({ theme }) => ({
    color: theme.colors.grey60,
    lineHeight: '1em',
    textDecoration: 'none',
    marginRight: theme.spaces[2],
    marginBottom: theme.spaces[1],
    '&:hover': {
        textDecoration: 'underline',
    },
    p: {
        lineHeight: '1em',
    },
}));
