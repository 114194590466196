import styled from '@emotion/styled';
import { Link, Text } from '~/shared/components';

export const StyledThreeColumnList = styled.div({
    '& .three-column-grid': {
        display: 'flex',
        marginLeft: -30,
        width: 'auto',
    },
    '& .three-column-grid_column ': {
        paddingLeft: 30,
        backgroundClip: 'padding-box',
    },
});

export const StyledImage = styled.div({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
});

export const StyledMetaInfo = styled.div(() => ({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
}));

export const StyledListItemTitle = styled(Text)(() => ({
    marginTop: '30px',
}));

export const StyledListItem = styled.article({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 100,
    [`${StyledMetaInfo}`]: {
        marginTop: '20px',
    },
    [`${Link}`]: {
        textDecoration: 'none',
        ':hover': {
            textDecoration: 'underline',
        },
    },
});
